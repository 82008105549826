import YUVCanvas from '../../libs/YUVCanvas';
import Decoder from '../../libs/Decoder';
import PLUGINFREE from '../constants/pluginfree';
import MicroEvent from '@vivotek/lib-utility/microevent';

/**
 * A simple raw bitstream player interface.
 *
 * @constructor
 */
function RawPlayer({
  canvas, width = 400, height = 300, codec = 'HEVC', workerSrc, autoplay = true, mode = 'live'
}) {
  this.BUFFER_LENGTH = 500; // 500 msec
  this.BUFFERED_LENGTH = 60; // 1 min

  const webglCanvas = new YUVCanvas({
    canvas,
    width,
    height,
    contextOptions: {},
  });

  this.default_workerSrc = {
    H264: PLUGINFREE.WORKER_264_SRC,
    HEVC: PLUGINFREE.WORKER_265_SRC
  };

  this.canvas = document.createElement('canvas');
  this.context = this.canvas.getContext('2d');
  this.canvas.width = width;
  this.canvas.height = height;

  this.optCanvas = canvas;
  this.glCanvas = webglCanvas;
  this.status_cb = null;
  this.error_cb = null;

  this.streamStartTime = 0;
  this.timeMapInfo = {};
  this.timeMapImage = {};
  this.$_currentTime = 0; // sec
  this.dropMode = false; // sec
  this.worker_src = workerSrc || this.default_workerSrc[codec];
  this.autoplay = autoplay;
  this.passedTimeMapFrame = {};

  this.paused = false;
  this.playing = false;
  this.nextFrame = null;
  this.needSimulateNotifies = [];
  this.buffered = [];
  this.isLiveMode = mode === 'live';
  this.isFirstIframeReceived = false;

  this.$_playbackRate = 1; // 0.25 ~ 5
  Object.defineProperty(this, 'playbackRate', {
    get() {
      return this.$_playbackRate;
    },
    set(rate) {
      if (rate <= 0) {
        return;
      }

      this.$_playbackRate = rate;

      if (!this.decoder) {
        return;
      }

      if (rate < 1) {
        this.setDecoderQueueLimit(1 / rate);
      } else {
        this.setDecoderQueueLimit(1);
      }
    }
  });

  Object.defineProperty(this, 'currentTime', {
    get() {
      return this.$_currentTime;
    },
    set(sec) {
      const secDelta = this.$_currentTime - sec;

      if (this.$_currentTime <= sec) {
        this.$_currentTime = sec;

        if (this.paused) {
          this.streamStartTime += secDelta * 1000;
        }
      }
      else if (this.getKeepedLength() >= secDelta) {
        Object.keys(this.passedTimeMapFrame)
          .filter((pts) => pts >= sec * 1000)
          .forEach((pts) => {
            const { info, frame } = this.passedTimeMapFrame[pts];

            this.timeMapInfo[pts] = info;
            this.timeMapImage[pts] = frame;

            delete this.passedTimeMapFrame[pts];
          });
        this.$_currentTime = sec;
        this.streamStartTime += secDelta * 1000;
        if (this.paused) { this.render() }
      }
    }
  });

  this.setDecoderQueueLimit = (multiple = Infinity) => {
    if (!this.decoder) {
      return;
    }

    this.decoder.queueLimit = Decoder.THRESHOLD * multiple;
  };

  this.reset();
  this.set_status_callback((msg, params) => {
    switch (msg) {
      case 'player_inited':
        this.trigger('inited');
        break;
      case 'stopped':
        this.streamStartTime = 0;
        this.trigger('stopped');
        break;
      case 'resolution':
        [this.canvas.videoWidth, this.canvas.videoHeight] = params;
        break;
      case 'output_avg_fps':
      case 'output_inst_fps':
      case 'input_frame':
      case 'output_frame':
      default:
        break;
    }
  });
  if (!autoplay) {
    return;
  }
  // check for autoplay
  this.autoplayTimeout = setTimeout(() => {
    delete this.autoplayTimeout;
    if (!this.streamStartTime && this.getBufferedLast()) {
      this.startToPlay();
    }
  }, this.BUFFER_LENGTH * 2);
}

RawPlayer.prototype.clear = function clear() {
  this.timeMapInfo = {};
  this.timeMapImage = {};
  this.reset();
};

RawPlayer.prototype.release = function release() {
  this.output_start = null;
  this.output_frames = 0;
  this.inst_output_start = null;
  this.inst_output_frames = 0;
  this.input_start = null;
  this.input_frames = 0;
  this.inst_input_start = null;
  this.inst_input_frames = 0;
  if (this.decoder) {
    this.decoder.free();
  }

  this.streamStartTime = 0;
  this.timeMapInfo = {};
  this.timeMapImage = {};
  this.currentTime = 0;

  if (this.autoplayTimeout) {
    clearTimeout(this.autoplayTimeout);
  }
  if (this.processBufferTimeout) {
    clearTimeout(this.processBufferTimeout);
  }

  this.buffered.length = 0;
};

RawPlayer.prototype.reset = function reset() {
  this.output_start = null;
  this.output_frames = 0;
  this.inst_output_start = null;
  this.inst_output_frames = 0;

  this.input_start = null;
  this.input_frames = 0;
  this.inst_input_start = null;
  this.inst_input_frames = 0;
  this.gapped = false;

  if (this.decoder) {
    this.remove_decoder(this.decoder);
  }
  this.decoder = this.create_decoder();
};

RawPlayer.prototype.create_decoder = function createDecoder() {
  const decoder = new Decoder({
    worker: this.worker_src
  });
  decoder.set_image_callback((data) => {
    this.processDecoderEvent(data);
  });
  decoder.set_ready_callback(() => {
    this.set_status('player_inited', null);
  });
  decoder.set_dropped_callback(() => {
    this.gapped = true;
  });

  return decoder;
};

RawPlayer.prototype.remove_decoder = function removeDecoder(decoder) {
  if (decoder) {
    decoder.free();
  }
};

/** @expose */
RawPlayer.prototype.set_status_callback = function setStatusCallback(callback) {
  this.status_cb = callback;
};

RawPlayer.prototype.set_status = function setStatus(...args) {
  if (this.status_cb) {
    this.status_cb.apply(this.status_cb, args);
  }
};

/** @expose */
RawPlayer.prototype.set_error_callback = function setErrorCallback(callback) {
  this.error_cb = callback;
};

RawPlayer.prototype.set_error = function setError(error, message) {
  this.trigger('error', message);

  if (this.error_cb) {
    this.error_cb(error, message);
  }
};

RawPlayer.prototype.getBufferedFirst = function getBufferedFirst() {
  const getBuffered = Object.keys(this.timeMapImage)
    .sort((a, b) => (Number(a) > Number(b) ? -1 : 1));

  let first = getBuffered.pop();

  do {
    if (this.currentTime <= (first / 1000)) {
      return Number(first);
    }

    delete this.timeMapImage[first];

    first = getBuffered.pop();
  } while (first);

  return undefined;
};

RawPlayer.prototype.getBufferedLast = function getBufferedLast() {
  const last = Object.keys(this.timeMapImage)
    .sort((a, b) => (Number(a) <= Number(b) ? -1 : 1))
    .pop();
  if (last) {
    return Number(last);
  }
  return undefined;
};

RawPlayer.prototype.getBufferedLength = function getBufferedLength() {
  return Object.keys(this.timeMapImage).length;
};

RawPlayer.prototype.processDecoderEvent = function processDecoderEvent({
  width, height, y, u, v, pts, stridey, strideu, stridev
}) {
  const { gapped } = this;
  this.timeMapImage[pts] = {
    width, height, y, u, v, stridey, strideu, stridev, gapped
  };
  const bufferLength = this.getBufferedLength();
  const info = this.timeMapInfo[pts];

  if (!info) {
    return;
  }

  const canAutoPlay = this.autoplay && !this.streamStartTime && this.getBufferedLast() >= this.BUFFER_LENGTH;
  const isPlaying = this.streamStartTime && !this.paused;
  const needToRender = bufferLength <= 1 || !this.nextFrame;
  const needRenderManually = isPlaying && needToRender;

  if (canAutoPlay) {
    this.startToPlay();
  } else if (needRenderManually) {
    const passed = Date.now() - this.streamStartTime;
    const expired = (passed - info.timestamp.video) / 1000;
    if (this.isLiveMode && expired >= this.BUFFERED_LENGTH) {
      this.trigger('error', 'Buffer length limitation');
    }

    if (passed >= info.timestamp.video) {
      this.render();
    } else {
      this.reserveNextFrame();
    }
  }
  this.gapped = false;
};

RawPlayer.prototype.startToPlay = function startToPlay() {
  const current = this.currentTime * 1000;

  this.streamStartTime = Date.now() - current;
  this.render();
};

RawPlayer.prototype.render = function render() {
  let current;

  if (this.isLiveMode) {
    const delta = Date.now() - this.streamStartTime;

    current = Object.keys(this.timeMapImage)
                .map((time) => Number(time))
                .filter((time) => time <= delta)
                .sort((a, b) => a - b)
                .pop();
  } else {
    current = this.getBufferedFirst();
  }

  this.nextFrame = null;

  if (isNaN(current)) {
    return;
  }

  const {
    width, height, y, u, v, stridey, strideu, stridev
  } = this.timeMapImage[current];

  const notify = this.timeMapInfo[current];

  this.set_status('resolution', [width, height]);

  if (this.glCanvas.width !== width || this.glCanvas.height !== height) {
    console.log('change resolution');
    const newCanvas = this.optCanvas;
    newCanvas.width = width;
    newCanvas.height = height;

    const webglCanvas = new YUVCanvas({
      canvas: newCanvas,
      contextOptions: {},
      width,
      height,
    });
    this.glCanvas = webglCanvas;
    this.canvas.width = width;
    this.canvas.height = height;

    this.reset();
  }
  this.glCanvas.drawNextOutputPicture({
    yData: y,
    uData: u,
    vData: v,
    yDataPerRow: stridey,
    uDataPerRow: strideu,
    vDataPerRow: stridev
  });

  this.context.drawImage(this.optCanvas, 0, 0, width, height);
  this.currentTime = Number(current) / 1000;

  Object.keys(this.timeMapImage).filter((pts) => pts <= current)
    .forEach((pts) => delete this.timeMapImage[pts]);
  Object.keys(this.timeMapInfo).filter((pts) => pts <= current)
    .forEach((pts) => delete this.timeMapInfo[pts]);
  if (!this.playing) {
    this.trigger('play');
    this.playing = true;
  }
  this.trigger('timeupdate', notify);

  if (this.paused) {
    return;
  }

  this.reserveNextFrame();
};

RawPlayer.prototype.decodeOneFrame = function decodeOneFrame(bitstream, info) {
  this.buffered.push({ bitstream, info });

  if (this.buffered.length > 1) {
    return;
  }

  this.processBufferTimeout = setTimeout(() => this.processBuffer(), 0);
};

RawPlayer.prototype.processBuffer = function processBuffer() {
  if (!this.decoder || !this.buffered.length) {
    return;
  }

  const iframeIndexs = this.buffered.map((data, index) => data.info.IFRAME ? index : -1).filter(val => val >= 0);

  if (this.isLiveMode && iframeIndexs.length > 2) {
    // drop some frames
    const index = iframeIndexs.slice(-2).shift();
    this.buffered = this.buffered.slice(index);
  }

  delete this.processBufferTimeout;

  const { bitstream, info } = this.buffered.shift();
  const pts = info && info.timestamp ? info.timestamp.video : 0;

  if (info.IFRAME && !this.isFirstIframeReceived) {
    this.isFirstIframeReceived = true;
  }

  if (!this.isFirstIframeReceived && !info.IFRAME) {
    // skip process until first frame is iframe
  } else if (!this.timeMapInfo[pts]) {
    const buffer = new ArrayBuffer(4 + bitstream.length);
    const dv = new DataView(buffer);
    dv.setUint32(0, pts, true);
    const data = new Uint8Array(buffer);
    data.set(bitstream, 4);

    this.decoder.push_data(data, info.IFRAME);
    this.timeMapInfo[pts] = info;
  }

  if (this.buffered.length <= 0) {
    return;
  }
  this.processBufferTimeout = setTimeout(() => this.processBuffer(), 8);
};

RawPlayer.prototype.pause = function pause() {
  if (this.paused) {
    return;
  }

  this.setDecoderQueueLimit();
  this.paused = true;
  this.pauseStart = Date.now();

  clearTimeout(this.nextFrame);
  clearTimeout(this.nextNotify);
  this.nextFrame = null;
  this.nextNotify = null;
  this.trigger('pause');
};

RawPlayer.prototype.play = function play() {
  if (this.paused) {
    this.paused = false;
    this.streamStartTime += Date.now() - this.pauseStart;
    this.reserveNextFrame();
    this.trigger('play');

    delete this.pauseStart;

    const queueLimit = this.playbackRate >= 1 ? 1 : 1 / this.playbackRate;
    this.setDecoderQueueLimit(queueLimit);
  } else {
    this.startToPlay();
  }
  return Promise.resolve();
};

RawPlayer.prototype.reserveNextFrame = function reserveNextFrame() {
  const { playbackRate } = this;
  const next = this.getBufferedFirst();
  const now = Date.now();
  const nextRender = this.streamStartTime + next - now;

  let nextRenderWithRate;
  let delta;

  if (isNaN(next)) {
    return;
  }

  if (playbackRate >= 1) {
    nextRenderWithRate = nextRender / playbackRate;
    delta = nextRender - nextRenderWithRate;
  } else {
    const current = Math.floor(this.currentTime * 1000);
    const interval = next - current;
    // adjust for low rate playback
    const passed = interval - nextRender;

    const intervalWithRate = interval / playbackRate;

    nextRenderWithRate = intervalWithRate - passed;
    // delta value is used to migrate startTime
    delta = interval - intervalWithRate - passed;
  }

  this.streamStartTime -= delta;

  this.nextFrame = setTimeout(() => this.render(), nextRender >= 0 ? nextRenderWithRate : 0);

  const nextNotify = this.timeMapImage[next];

  if (nextNotify?.gapped) {
    this.simulateNotifiesTill(next);
  }
};

RawPlayer.prototype.simulateNotifiesTill = function simulateNotifiesTill(pts) {
  const { currentTime } = this;
  const needSimulates = Object.keys(this.timeMapInfo)
    .filter((time) => currentTime < time && time < pts)
    .sort((a, b) => (Number(a) < Number(b) ? -1 : 1));

  needSimulates.forEach((time) => {
    const notify = this.timeMapInfo[time];
    delete this.timeMapInfo[time];

    this.needSimulateNotifies.push(notify);
  });

  this.simulateNotifies();
};

RawPlayer.prototype.simulateNotifies = function simulateNotifies() {
  if (!this.needSimulateNotifies.length) {
    return;
  }

  const notify = this.needSimulateNotifies.shift();
  const next = notify.timestamp.video;

  const now = Date.now();
  const nextRender = this.streamStartTime + next - now;
  const nextRenderWithRate = nextRender / this.playbackRate;

  this.nextNotify = setTimeout(() => {
    if (this.paused) {
      return;
    }

    this.currentTime = Number(next) / 1000;
    this.trigger('timeupdate', notify);
    this.simulateNotifies();
  }, nextRender >= 0 ? nextRenderWithRate : 0);
};

/** @expose */
RawPlayer.prototype.stop = function stop() {
  this.playing = false;
  this.set_status('stopped');
  this.remove_decoder(this.decoder);
  clearTimeout(this.nextFrame);
  clearTimeout(this.nextNotify);
};

/** @expose */
RawPlayer.prototype.snapshot = function snapshot({ width, height } = {}) {
  const tmpCanvas = document.createElement('canvas');
  const snapshotCtx = tmpCanvas.getContext('2d');
  tmpCanvas.width = width ?? this.canvas.width;
  tmpCanvas.height = height ?? this.canvas.height;
  snapshotCtx.drawImage(this.canvas, 0, 0, tmpCanvas.width, tmpCanvas.height);
  return new Promise((resolve) => tmpCanvas.toBlob(resolve));
};

RawPlayer.prototype.getKeepedLength = function getKeepedLength() {
  return 0;
};

export default MicroEvent.mixin(RawPlayer);
