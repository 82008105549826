import Liveview from './liveview';
import RawPlayer from '../player/RawPlayer';
import { isSafari } from '@vivotek/lib-rtsp-protocol/src/rtspTools';
import LocalTime from '@vivotek/lib-utility/local_time';
import MicroEvent from '@vivotek/lib-utility/microevent';

function Playback({
  getInterval = 20 * 1000, // 10 sec
  snapshotMode = false,
  duration = Infinity
} = {}) {
  Liveview.apply(this, arguments);

  this.getInterval = getInterval;
  this.snapshotMode = snapshotMode;
  this.duration = duration * 1000;
  this.firstNotify = null;

  this.getIntervalBuffer = 5000;
  this.updated = false;
  this.isPause = false;
  this.playByFrame = false;

  this.GET_INTERVAL = this.getInterval;
  this.GET_INTERVAL_BUFFER = this.getIntervalBuffer;

  this.ssmuxType = this.snapshotMode ? 2 : 1;
  this.fixedPlaybackRate = true;
  this.backupAudioPackets = [[]];
  this.backupAudioPacketsLength = 3;
  this.backupVideoEvents = {};
  this.backupMetjsEvents = {};

  this.isPause = false;
  this.playbackReady = false;
  this.playbackOffset = 0;

  this.currentCodec = null;
  this.isInfoPassedError = false;
}

Object.assign(Playback.prototype, Liveview.prototype);

Playback.prototype.onVideoPlay = function onVideoPlay() {
  if (this.isPause && this.audioContext) {
    this.audioContext.resume();
  }

  this.isPause = false;

  Liveview.prototype.onVideoPlay.apply(this, arguments);
};

Playback.prototype.onVideoTimeUpdate = function onVideoTimeUpdate() {
  if (this.checkShouldSkipTimeUpdate()) { return; }

  const { currentTime } = this.player;

  Liveview.prototype.onVideoTimeUpdate.apply(this, arguments);

  const notifications = Liveview.getMapList(this.videoTimeMapNotify)
    .map((timestamp) => this.videoTimeMapNotify[timestamp]);
  const continueTimestamp = this.checkContinuePlay(
    currentTime * 1000,
    this.getIntervalBuffer,
    notifications,
    this.prevNotify
  );

  if (this.checkDurationSatisfied()) {
    this.stop();
    return;
  }

  if (!continueTimestamp && this.isInfoPassedError === true) {
    this.isInfoPassedError = false;
    this.trigger('error', new Error('Failed to get stream.'));
  }

  if (!this.updated || !continueTimestamp) { return; }

  this.updated = false;
  this.continuePlay(continueTimestamp).catch(() => {
    this.trigger('error', new Error('Continuous play failed'));
  });
};

Playback.prototype.backupVideoEvent = function backupVideoEvent(frameInfo) {
  this.backupVideoEvents[frameInfo.timestamp.video] = frameInfo;

  Object.keys(this.backupVideoEvents)
    .filter((pts) => this.player && pts < this.player.currentTime - this.KEEP_PASSED_LENGTH)
    .forEach((pts) => delete this.backupVideoEvents[pts]);
};

Playback.prototype.backupMetjsEvent = function backupMetjEvent(timestamp, frameInfo) {
  this.backupMetjsEvents[timestamp] = frameInfo;

  Object.keys(this.backupMetjsEvents)
    .filter((pts) => this.player && pts < this.player.currentTime - this.KEEP_PASSED_LENGTH)
    .forEach((pts) => delete this.backupMetjsEvents[pts]);
};

Playback.prototype.triggerExpireNotify = function triggerExpireNotify(currentTime) {
  const { videoTimeMapNotify } = this;
  const videoTimeMapKeysList = Liveview.getMapList(videoTimeMapNotify);

  videoTimeMapKeysList.forEach((time) => {
    if (currentTime < time) { return; }

    const orginInfo = videoTimeMapNotify[time];
    const notify = this.createNotify(orginInfo);

    this.triggerNotify(notify);
    this.backupVideoEvent(orginInfo);

    delete videoTimeMapNotify[time];
    // trigger metj event
    const { metjTimestampMapNotify } = this;
    const metjTimeMapKeysList = Liveview.getMapList(metjTimestampMapNotify);
    const { stream } = orginInfo.timestamp; // timestamp from camera

    metjTimeMapKeysList.forEach((timestamp) => {
      const timestampInList = Number(timestamp);

      if (stream < timestampInList) { return; }

      const metjs = metjTimestampMapNotify[timestamp];

      this.backupMetjsEvent(timestamp, metjs);

      metjs.forEach((metj) => {
        this.trigger('metj', metj);
      });

      delete metjTimestampMapNotify[timestamp];
    });
  });
};

Playback.prototype.triggerNotify = function triggerNotify(notify) {
  if (!this.firstNotify) {
    this.firstNotify = notify;
  }

  Liveview.prototype.triggerNotify.call(this, notify);
};

Playback.prototype.checkDurationSatisfied = function checkDurationSatisfied() {
  return this.getPlayerCurrentTime() * 1000 >= this.duration;
};

Playback.prototype.checkContinuePlay = function checkContinuePlay(
  currentTime, bufferedTime, unpoppedNotify, latestPoppedNotify
) {
  const lastNotify = unpoppedNotify[unpoppedNotify.length - 1];
  const lastVideoTime = lastNotify ? lastNotify.timestamp.video : 0;

  let continueTimestamp;

  if (!lastNotify && !lastVideoTime) {
    return;
  }
  // console.log(unpoppedNotify.length, currentTime + bufferedTime, lastVideoTime);
  if (unpoppedNotify.length <= 1 || currentTime + bufferedTime >= lastVideoTime) {
    if (lastNotify && lastNotify.timestamp) {
      continueTimestamp = lastNotify.timestamp.display.stream;
    } else {
      continueTimestamp = latestPoppedNotify.timestamp.stream;
    }
  }

  return continueTimestamp;
};

Playback.prototype.checkShouldSkipTimeUpdate = function checkShouldSkipTimeUpdate() {
  return this.snapshotMode || !this.player
    || (this.player.paused && !this.playByFrame);
};

Playback.prototype.play = function play(startTime, tzoffs) {
  if (this.isPause) {
    return this.player.play().then(() => {
      this.setPlayerTimeout();

      if (!this.playByFrame) { return; }

      this.playByFrame = false;
      this.switchAudio();
    });
  }
  const playbackStartTime = startTime instanceof Date ? startTime.getTime() : startTime;
  if (!this.snapshotMode) {
    this.isLocalTime = tzoffs !== undefined;
    this.tzoffs = tzoffs;
    this.startLocalTime = new LocalTime({
      timestamp: playbackStartTime,
      tzoffs: (tzoffs === undefined ? 0 : tzoffs)
    });
    this.updateUrlTimeRange(playbackStartTime);
  }
  if (isSafari) { this.safariPause = true; }

  return Liveview.prototype.play.call(this);
};

Playback.prototype.stop = function stop() {
  this.playbackReady = false;
  return Liveview.prototype.stop.call(this).then(() => {
    this.updated = false;
    this.isPause = false;
    this.currentCodec = null;
    this.backupVideoEvents = {};
    this.backupMetjsEvents = {};
  });
};

Playback.prototype.setResync = function setResync() {
  // do nothing
};

Playback.prototype.processInfoEvent = function processInfoEvent(ptr) {
  if (this.snapshotMode) { return; }

  Liveview.prototype.processInfoEvent.call(this, ptr);
};

Playback.prototype.processAudio = function processAudio(packet, sampleRate, duration, info) {
  if (this.snapshotMode) { return; }

  Liveview.prototype.processAudio.apply(this, arguments);
};

Playback.prototype.processAudioEvent = function processAudioEvent(packet, sampleRate, duration) {
  if (this.snapshotMode) { return; }

  Liveview.prototype.processAudioEvent.apply(this, arguments);
}

Playback.prototype.backupAudio = function processAudio(packet, sampleRate, duration, info) {
  this.backupAudioPackets[0].push({
    packet, sampleRate, duration, info
  });
};

Playback.prototype.processInfo = function processInfo(info) {
  if (this.snapshotMode) { return; }

  if (info.timestamp && info.timestamp.video !== undefined) {
    const displayLocalTime = new LocalTime({
      timestamp: info.timestamp.display.stream,
      tzoffs: info.timestamp.display.tzoffs
    });
    if (!this.playing && this.startLocalTime + this.duration < displayLocalTime) {
      return this.stopping ? undefined : this.stop().then(() => {
        this.trigger('error', new Error('Failed to get stream.'));
      });
    }

    const { codec } = info;
    // check codec changed
    if (!this.currentCodec) {
      this.currentCodec = codec;
    } else if (this.currentCodec !== codec) {
      this.trigger('notify', this.createNotify(info));
      const error = `codec: ${this.currentCodec} has been changed`;
      this.trigger('error', new Error(error));
      this.currentCodec = codec;
    }
  }

  Liveview.prototype.processInfo.apply(this, arguments);

  // get video success
  if (info.success) {
    this.updated = true;
    // flag audio can update offsets after processing audio packet
    this.resetAudio();
  }

  if (!info.timestamp || !(info.timestamp.video >= 0) || info.preroll || this.playbackReady) { return; }

  this.playbackOffset = info.timestamp.video / 1000;
  this.playbackReady = true;
  this.firstNotify = this.createNotify(info);
  this.firstNotify.timestamp.video = 0;

  Liveview.getMapList(this.videoTimeMapNotify)
    .filter((video) => video < info.timestamp.video)
    .forEach((video) => delete this.videoTimeMapNotify[video]);
};

Playback.prototype.handleInfoError = function handleInfoError(error) {
  if (this.checkShouldSkipTimeUpdate()) {
    return this.trigger('error', new Error(error));
  }
  const { currentTime } = this.player;
  const notifications = Liveview.getMapList(this.videoTimeMapNotify)
  .map((timestamp) => this.videoTimeMapNotify[timestamp]);
  const continueTimestamp = this.checkContinuePlay(
    currentTime * 1000,
    this.getIntervalBuffer,
    notifications,
    this.prevNotify
  );

  if (continueTimestamp > 0) {
    this.isInfoPassedError = true;
    return;
  }
}

Playback.prototype.initVideo = function initVideo(video) {
  video.muted = true;
  video.autoplay = false;
  video.addEventListener('play', () => {
    this.onVideoPlay();
  });
  video.addEventListener('timeupdate', () => {
    if (video.paused) { return; }

    this.onVideoTimeUpdate();
  });
  video.addEventListener('pause', () => {
    this.onVideoPause();
  });
  video.addEventListener('loadeddata', () => {
    if (video.readyState === 4 && this.autoplay) { video.play(); }
  });
};

Playback.prototype.gainRawPlayerOptions = function gainRawPlayerOptions(...args) {
  const options = Liveview.prototype.gainRawPlayerOptions.apply(this, args);

  options.autoplay = false;
  options.mode = 'playback';

  return options;
};

Playback.prototype.onSourceBufferUpdated = function onSourceBufferUpdated(video, sourceBuffer, queue) {
  queue.shift();

  const play = () => video.play().catch((err) => {
    this.trigger('error', err);
  });

  if (this.safariPause) {
    play().then((_) => {
      this.safariPause = false;
    });
  }

  const bufferEnd = sourceBuffer.buffered.length > 0 ? sourceBuffer.buffered.end(0) : 0;

  if (!this.playing) {
    // play();
    if (this.playbackReady && bufferEnd >= this.playbackOffset) {
      video.currentTime = this.playbackOffset;
      const { isPause } = this;
      play().then((_) => {
        if (isPause) {
          this.pause();
        }
      });
    }
  }

  if (!queue.length || this.mediaSource.readyState !== 'open' || this.sourceBuffer.updating) { return; }
  sourceBuffer.appendBuffer(queue[0]);
};

Playback.prototype.processStreamNotify = function processStreamNotify(notify) {
  if (!notify || !notify.timestamp) { return; }

  Liveview.prototype.processStreamNotify.apply(this, arguments);
  if (this.isLocalTime) {
    this.tzoffs = notify.timestamp.display.tzoffs;
  }
};

Playback.prototype.getLastVideoTimestamp = function getLastVideoTimestamp() {
  const timestamps = Object.keys(this.videoTimeMapNotify).sort((a, b) => Number(b) - Number(a));
  return Number(timestamps[0] ? timestamps[0] : 0);
};

Playback.prototype.continuePlay = function continuePlay(date) {
  if (date) { this.updateUrlTimeRange(date); }

  this.backupAudioPackets.unshift([]);
  this.backupAudioPackets.length = this.backupAudioPacketsLength;

  return this.sendSetup(this.url)
    .then(([status, session]) => {
      this.rtspSession = session;
      this.sendPlay(this.url, session);
    }, (e) => {
      console.error('continuePlay streaming fail');
      return Promise.reject(e);
    });
};

Playback.prototype.updateUrlTimeRange = function updateUrlTimeRange(timestamp) {
  this.url = this.url.replace(/&[SE][L]?TIME=[\w_.]+/ig, '')
             + this.genTimeRange(timestamp, this.getInterval, this.isLocalTime, this.tzoffs);
};

Playback.prototype.genTimeRange = function genTimeRange(startTime, interval, isLocalTime, tzoffs) {
  function formatDatetime(timestamp) {
    const date = new Date(timestamp);
    return [
      `${date.getUTCFullYear()}`.padStart(4, '0'),
      `${date.getUTCMonth() + 1}`.padStart(2, '0'),
      `${date.getUTCDate()}`.padStart(2, '0'),
      '_',
      `${date.getUTCHours()}`.padStart(2, '0'),
      `${date.getUTCMinutes()}`.padStart(2, '0'),
      `${date.getUTCSeconds()}`.padStart(2, '0'),
      '_',
      `${date.getUTCMilliseconds()}`.padStart(3, '0')
    ].join('');
  }

  let sTime = startTime;
  let eTime = sTime + interval;

  if (!isLocalTime) {
    return `&STIME=${formatDatetime(sTime)
    }&ETIME=${formatDatetime(eTime)}`;
  }

  const timezone = tzoffs === undefined ? 0 : tzoffs;
  sTime -= timezone * 1000;
  eTime = sTime + interval;

  return `&SLTIME=${formatDatetime(sTime)
  }&ELTIME=${formatDatetime(eTime)}`;
};

Playback.prototype.pause = function pause() {
  this.player.pause();
  this.isPause = true;
  this.clearPlayerTimeout();
  if (this.audioContext) {
    this.audioContext.suspend();
  }
};

Playback.prototype.setPlaybackRate = function setPlaybackRate(rate) {
  if (this.player.playbackRate === rate) { return; }

  this.player.playbackRate = rate;

  if (rate >= 1) {
    this.getInterval = this.GET_INTERVAL * rate;
    this.getIntervalBuffer = this.GET_INTERVAL_BUFFER * rate;
  } else {
    this.getInterval = this.GET_INTERVAL;
    this.getIntervalBuffer = this.GET_INTERVAL_BUFFER;
  }

  if (rate === 1) {
    this.unmute();
    this.speedUp = false;

    if (this.player instanceof RawPlayer) {
      setTimeout(() => this.switchAudio(), 1000);
    } else {
      this.switchAudio();
    }
  } else {
    this.setMute();
    this.speedUp = true;
  }
};

Playback.prototype.switchAudio = function switchAudio() {
  if (this.player.playbackRate !== 1) { return; }
  // remove current audiiContext and create a new one
  this.removeAudio(this.audioContext, this.gainNode);
  this.createAudio(this.volume, this.mute)
    .then(([audioContext, gainNode]) => {
      this.audioContext = audioContext;
      this.gainNode = gainNode;
    })
    .then(() => {
      // append audio packet from 'backupAudioPackets'
      for (let i = this.backupAudioPacketsLength - 1; i >= 0; i -= 1) {
        if (this.backupAudioPackets[i]) {
          this.backupAudioPackets[i].forEach(({
            packet, sampleRate, duration, info
          }) => {
            if (!info) { return; }

            Liveview.prototype.processAudio.call(this, packet, sampleRate, duration, info);
          });
        }
      }
    })
    .then(() => this.initAudio())
    .then(() => {
      // filter all passed audio packets
      const index = this.audioQueue.findIndex(({ info }) => this.audioContextStart + info.timestamp.audio >= 0);
      this.audioQueue = this.audioQueue.slice(index);
      // force process audio packets in queue
      return this.processAudioQueue(this.audioQueue, true)
        .then(this.playAudioPacket.bind(this));
    })
    .then(() => {
      if (this.isPause) {
        this.audioContext.suspend();
      }
    })
    .catch((err) => console.error('switchAudio err', err));
};

Playback.prototype.nextFrame = function nextFrame() {
  this.pause();
  this.playByFrame = true;

  if (this.player instanceof RawPlayer) {
    this.player.render();
  } else if (this.player && this.player.currentTime) {
    this.player.currentTime += (1000 / this.fps) / 1000;
    this.onVideoTimeUpdate();
  }
};

Playback.prototype.processHEVCEvent = function processHEVCEvent(packet) {
  Liveview.prototype.processHEVCEvent.call(this, packet);

  if (this.player && this.playbackReady && !this.playing) {
    this.player.currentTime = this.playbackOffset;
    this.player.play();
  }
};

Playback.prototype.setPlayerTimeout = function setPlayerTimeout() {
  if (this.playByFrame) { return; }

  Liveview.prototype.setPlayerTimeout.call(this);
};

Playback.prototype.destroy = function destroy() {
  Liveview.prototype.destroy.call(this);

  this.firstNotify = null;
};

Playback.prototype.getPlayerCurrentTime = function getPlayerCurrentTime() {
  if (!this.player) { return 0; }

  return this.player.currentTime - this.playbackOffset;
};

Playback.prototype.setMute = function setMute() {
  this.mute = true;

  if (!this.isPause) { this.resumeAudio(); }

  if (!this.gainNode || this.speedUp) { return; }
  this.gainNode.gain.value = 0;
};

Playback.prototype.unmute = function unmute() {
  this.mute = false;

  if (!this.isPause) { this.resumeAudio(); }

  if (!this.gainNode || this.speedUp) { return; }

  this.gainNode.gain.value = this.volume;
};

Playback.prototype.setVolume = function setVolume(value) {
  this.mute = false;
  this.volume = value;

  if (!this.isPause) { this.resumeAudio(); }

  if (!this.gainNode || this.speedUp) { return; }

  this.gainNode.gain.value = value;
};

Playback.prototype.createVideo = function createVideo() {
  return Liveview.prototype.createVideo.apply(this, arguments)
    .then((res) => {
      const { player } = res;

      player.forward = (sec) => {
        if (this.sourceBufferStart <= player.currentTime - sec ) {
          player.currentTime -= sec;
          return Promise.resolve();
        }
        else {
          return Promise.reject(new Error('keeped content error'));
        }
      };

      return res;
    });
};

Playback.prototype.createRawPlayer = function createRawPlayer() {
  return Liveview.prototype.createRawPlayer.apply(this, arguments)
    .then((res) => {
      const { player } = res;

      player.forward = (sec) => {
        if (player.getKeepedLength() > sec ) {
          player.currentTime -= sec;
          return Promise.resolve();
        }
        else {
          return Promise.reject(new Error('keeped content error'));
        }
      };

      if (player && this.playbackReady && !this.playing) {
        player.currentTime = this.playbackOffset;
        player.play();
      }

      return res;
    });
};

Playback.prototype.forward = function forward(sec = 20) {
  return this.player.forward(sec)
    .then(() => {
      const { currentTime } = this.player;
      Object.keys(this.backupVideoEvents)
        .filter((pts) => pts >= currentTime * 1000)
        .forEach((pts) => {
          const event = this.backupVideoEvents[pts];
          const timestamp = event.timestamp.stream;

          this.videoTimeMapNotify[pts] = event;

          delete this.backupVideoEvents[pts];

          Object.keys(this.backupMetjsEvents)
            .filter(t => t >= timestamp)
            .forEach(t => {
              this.metjTimestampMapNotify[t] = this.backupMetjsEvents[t];

              delete this.backupMetjsEvents[t];
            });
        });
    })
    .then(() => this.switchAudio());
};

Playback.prototype.setStreamTimeout = function setStreamTimeout() {};

Playback.prototype.clearStreamTimeout = function clearStreamTimeout() {};

export default MicroEvent.mixin(Playback);
